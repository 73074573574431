import React from 'react';
import Seo from '../components/Seo.component';
import { StaticImage } from 'gatsby-plugin-image';
import tw from 'twin.macro';
import styled from 'styled-components';

const AboutWrapperStyles = styled.main`
  ${tw`container flex-col px-5 py-4 mx-auto`};
  .about__title {
    ${tw`container flex-col px-5 py-4 mx-auto`};
    h1 {
      ${tw`text-2xl font-medium text-center text-gray-900 lg:text-3xl`};
    }
  }
  .about__content {
    ${tw`container flex flex-col px-2 py-2 mx-auto md:flex-row`};
    &--img {
      ${tw`w-full px-6 mb-6 lg:px-0 lg:max-w-lg lg:w-full md:w-1/2 md:mb-0`};
      img {
        ${tw`object-cover object-center rounded-2xl`}
      }
    }
    &--detail {
      ${tw`flex flex-col text-center lg:flex-grow md:w-1/2 md:pl-8 md:items-start md:text-left`};
      p {
        ${tw`mb-1 text-sm leading-relaxed`};
      }
    }
  }
`;

export default function AboutPage() {
  return (
    <>
      <Seo title="Về chúng tôi" />
      <AboutWrapperStyles className="about">
        <div className="about__title">
          <h1 className="text-2xl font-medium text-center text-gray-900 lg:text-3xl title-font">
            CÀ PHÊ – CA CAO – BỘT GIẢI KHÁT HÒA TAN: NHÃ THY
          </h1>
        </div>
        <div className="about__content">
          <div className="about__content--img">
            <StaticImage
              src="../images/nguoisanglap.jpg"
              alt="Nguyễn Thành An - Người sáng lập công ty Nhã THY"
              className="object-cover object-center rounded-2xl"
            />
          </div>
          <div className="about__content--detail">
            <p>
              Sau hơn 18 năm hình thành và phát triển, Hãng cà phê trà NHÃ THY
              đã trang bị các dây chuyền thiết bị, máy móc hiện đại, áp dụng các
              công nghệ tiên tiến để cho ra đời những sản phẩm chất lượng cao.
              Từ 02 sản phẩm đầu tiên là trà ướp hương và cà phê xay truyền
              thống, đến nay hãng đã sản xuất và cho xuất xưởng hàng loạt các
              dòng sản phẩm chất lượng cao đáp ứng được nhu cầu và sự tiện lợi
              cho người tiêu dùng như trà, cà phê hòa tan, các loại thức uống bổ
              dưỡng, nông sản chế biến…{' '}
            </p>
            <p>
              Với hệ thống phân phối rộng khắp 64 tỉnh thành cả nước cộng với
              đội ngũ nhân viên phát triển thị trường chuyên nghiệp đã góp phần
              nâng cao vị thế của hãng NHÃ THY ngày càng lớn mạnh về quy mô, thị
              phần trong nước không những ngày càng được củng cố, mở rộng mà còn
              vươn ra các nước trong khu vực.
            </p>
            <p>
              Thực hiện phương châm “Không ngừng đổi mới và phát triển” để phục
              vụ khách hàng ngày một tốt hơn.{' '}
            </p>
            <br />
            <p>GIẤY PHÉP KINH DOANH SỐ : 3702199060.</p>
            <p>MÃ SỐ THUẾ SỐ : 3702199060. </p>
            <p>
              WEBSITE: <a href="http://nhathy.vn">nhathy.vn</a> –{' '}
              <a href="http://congtynhathy.com">congtynhathy.com</a> –{' '}
              <a href="http://menviet.vn">menviet.vn</a>
            </p>
            <p>
              CHUYÊN SẢN XUẤT VÀ PHÂN PHỐI CÁC LOẠI : BỘT GIẢI KHÁT HÒA TAN CÁC
              LOẠI – NGŨ CỐC DINH DƯỠNG ( DÀNH CHO MỌI LỨA TUỔI ) – NGŨ CỐC DINH
              DƯỠNG ( DÀNH CHO NGƯỜI ĂN KIÊNG ) – CA CAO – CÀ PHÊ (3 in 1) – CÀ
              PHÊ SẠCH CÁC LOẠI .
            </p>
            <p>
              CÁM ƠN QUÝ KHÁCH GẦN XA ĐÃ TIN DÙNG CÁC SẢN PHẨM CỦA NHÃ THY THÔNG
              TIN CÔNG TY.
            </p>
            <br />
            <p>Tên công ty: CÔNG TY TNHH MTV TM DV VT NHÃ THY</p>
            <p>Khu vực: BÌNH DƯƠNG</p>
            <p>Quy mô: 1-49 người </p>
            <p>Vốn đăng ký: 1.900.000.000 VNĐ </p>
            <p>Năm thành lập: 2000</p>
            <p>Chứng nhận: HÀNG VIÊT NAM CHẤT LƯỢNG CAO 2013 … 2018.</p>
            <p>Mô hình kinh doanh: Sản xuất,Thương mại </p>
            <p>
              Lĩnh vực: Bột giải khát hòa tan – Ngũ cốc dinh dưỡng – Cà phê – Ca
              cao.
            </p>
            <p>
              Bán các sản phẩm: BỘT GIẢI KHÁT HÒA TAN CÁC LOẠI – NGŨ CỐC DINH
              DƯỠNG (DÀNH CHO MỌI LỨA TUỔI) – NGŨ CỐC DINH DƯỠNG (DÀNH CHO NGƯỜI
              ĂN KIÊNG) – CA CAO – CÀ PHÊ (3 in 1) – CÀ PHÊ SẠCH CÁC LOẠI.
            </p>
          </div>
        </div>
      </AboutWrapperStyles>
    </>
  );
}
